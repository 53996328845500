import React, {useEffect, useState} from "react";
import SpechText from "../components/SpechText";
import FinishExam from "./FinishExam";
import {Link} from "react-router-dom";

var stringSimilarity = require("string-similarity");
const answerEmpty = require("./Quiz8Answer.json");

export default function Quiz8(props) {
    const [answer, setAnswer] = useState({...answerEmpty});
    const [pageNo, setPageNo] = useState(0);
    const [maxPage] = useState(5);

    const nextPage = () => {
        if (pageNo < maxPage - 1) setPageNo(pageNo + 1);
        // props.sendExam(answer,"QUIZ", false)
    };
    const prevPage = () => {
        if (pageNo > 0) setPageNo(pageNo - 1);
        // props.sendExam(answer,"QUIZ", false)
    };
    const [returnLink, setReturnLink] = useState("/mytasks");
    useEffect(() => {
        const isTeacher = window.location.href

        if (isTeacher.includes("teacher")) {
            setReturnLink("/teacher-contents/QUIZS")
            console.log(isTeacher)
        }
    }, [])
    const setStudentOpenAnswer = (
        skils,
        section,
        queationNumber,
        value,
        compare
    ) => {
        const temp = {...answer};
        temp.result[skils][section][queationNumber].student = value;
        let finalScore = temp.result[skils][section][queationNumber].weigth;

        var similarity = stringSimilarity.compareTwoStrings(
            clearText(value),
            clearText(temp.result[skils][section][queationNumber].answer)
        );
        finalScore =
            similarity *
            parseFloat(temp.result[skils][section][queationNumber].weigth);
        temp.result[skils][section][queationNumber].score = finalScore;

        if (
            finalScore >
            (temp.result[skils][section][queationNumber].weigth / 10) * 6
        ) {
            temp.result[skils][section][queationNumber].functionScore = true;
        } else {
            temp.result[skils][section][queationNumber].functionScore = false;
        }
        setAnswer(temp);
    };
    const getSpeechText = (text, questionNumber) => {
        setStudentOpenAnswer(0, 4, questionNumber, text, true);
    };

    const clearText = (text) => {
        let newText = text
            .replace(".", "")
            .replace("'", "")
            .replace("!", "")
            .replace(",", "")
            .replace("’", "")
            .replace("?", "")
            .replace("-", "")
            .replace("_", "");
        newText = newText.toLowerCase();
        return newText;
    };

    return (
        <div className="container">
            <div className="card mt-5 mb-5">
                <div className="card-header">
                    <div className="d-flex justify-content-center">
                        <h4>
                            <strong><Link to={returnLink}> RETURN TASK LIST</Link></strong>
                        </h4>
                    </div>
                </div>

                <div className="card-header">
                    <div className="d-flex justify-content-center">
                        <h1>
                            <strong> QUIZ CHAPTER 2 EPISODE 8</strong>
                        </h1>
                    </div>
                </div>
                <div className="card-body p-5">
                    <div>
                        {answer ? (
                            <React.Fragment>
                                <div style={{fontSize: "18pt"}}>
                                    {pageNo === 0 ? (
                                        <React.Fragment>
                                            <div className="alert alert-dark" role="alert">
                                                <strong> A. Choose the correct answer.</strong>
                                            </div>
                                            <div className="row">
                                                <strong>1. They received __________ call.</strong>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as1"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 1, "A")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        A) book
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as1"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 1, "B")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        B) mail
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as1"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 1, "C")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        C) food order
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as1"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 1, "D")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        D) SOS
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <strong>
                                                    2. The aid was requested by the ____________.
                                                </strong>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as2"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 2, "A")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        A) robots
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as2"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 2, "B")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        B) president
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as2"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 2, "C")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        C) headquarter
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as2"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 2, "D")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        D) police
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <strong>
                                                    3. They travel with ____________ to the head quarter.
                                                </strong>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as3"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 3, "A")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        A) Area C923
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as3"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 3, "B")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        B) Area C924
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as3"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 3, "C")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        C) Area C925
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as3"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 3, "D")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        D) Area C926
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <strong>
                                                    4. Dung beetle loves the vehicle because ____________
                                                    .
                                                </strong>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as4"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 4, "A")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        A) it is green
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as4"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 4, "B")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        B) it is soft
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as4"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 4, "C")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        C) it is round
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as4"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 4, "D")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        D) it smells
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <strong>5. Why did they send an SOS?</strong>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as5"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 5, "A")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        A) Trafic accident
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as5"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 5, "B")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        B) Energy shortage
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as5"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 5, "C")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        C) Health problem
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="as5"
                                                        onChange={(e) => setStudentOpenAnswer(0, 0, 5, "D")}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        D) Disagreement
                                                    </label>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                    {pageNo === 1 ? (
                                        <React.Fragment>
                                            <div className="alert alert-dark" role="alert">
                                                <strong> B. Listen and fill in the gaps.</strong>
                                            </div>
                                            <div className="alert alert-dark" role="alert">
                                                <audio
                                                    controls
                                                    className="w-100"
                                                    style={{backgroundColor: "black", height: 45}}
                                                >
                                                    <source
                                                        src={`https://app.axebug.com/axebug/assets/OUIZ_8_B_LISTENING.mp3`}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                            </div>
                                            <div className="paragraf">
                                                <strong>Robug 13:</strong> These are our friends. From
                                                now on, they can stroll around{" "}
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][1].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 1, e.target.value, true)
                                                    }
                                                />
                                                .
                                            </div>
                                            <div className="paragraf">
                                                <strong>Robug 13:</strong> You can go back to the{" "}
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][2].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 2, e.target.value, true)
                                                    }
                                                />
                                                . I will take care of our guests.
                                            </div>
                                            <div className="paragraf">
                                                <strong>WATCHMAN 2: </strong>Yes, sir!
                                            </div>
                                            <div className="paragraf">
                                                <strong>Robug 13: </strong> You have come a long way.{" "}
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][3].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 3, e.target.value, true)
                                                    }
                                                />
                                                . Eat and recharge your batteries. We will talk
                                                afterwards.
                                            </div>
                                            <div className="paragraf">
                                                <strong>TEN HOURS LATER</strong>
                                            </div>
                                            <div className="paragraf">
                                                <strong>Robug 13:</strong> My friends! So glad to see
                                                you. We need your help!
                                            </div>
                                            <div className="paragraf">
                                                <strong>Axebug: </strong>We couldn’t ignore your{" "}
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][4].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 4, e.target.value, true)
                                                    }
                                                />
                                                of course.
                                            </div>
                                            <div className="paragraf">
                                                <strong>Dung beetle: </strong>This place is splendid!
                                                Everything is round shaped!
                                            </div>
                                            <div className="paragraf">
                                                <strong>Ladybug:</strong> Tell me about it!
                                            </div>
                                            <div className="paragraf">
                                                <strong>Axebug:</strong> Why did you{" "}
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][5].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 5, e.target.value, true)
                                                    }
                                                />
                                                an S.OS.? What’s the problem?
                                            </div>
                                            <div className="paragraf">
                                                <strong>Robug 13: </strong>We arrived to this planet
                                                long before you ever lived.
                                            </div>
                                            <div className="paragraf">
                                                <strong>Axebug:</strong> What?
                                            </div>
                                            <div className="paragraf">
                                                <strong>Dung beetle:</strong> How long?
                                            </div>
                                            <div className="paragraf">
                                                <strong>Ladybug:</strong> I don’t get it!
                                            </div>
                                            <div className="paragraf">
                                                <strong>Robug 13:</strong> You will get all your
                                                answers.
                                            </div>
                                            <div className="paragraf">
                                                <strong>Robug 13:</strong> But first, hear me out.
                                            </div>
                                            <div className="paragraf">
                                                <strong>Robug 13:</strong> In order to continue life in
                                                our colony, we need your{" "}
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][6].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 6, e.target.value, true)
                                                    }
                                                />
                                                .
                                            </div>
                                            <div className="paragraf">
                                                <strong>Robug 13:</strong> We are dealing with{" "}
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][7].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 7, e.target.value, true)
                                                    }
                                                />
                                                .
                                            </div>
                                            <div className="paragraf">
                                                <strong>Robug 13:</strong> We made huge mistakes. And
                                                now, our colony is about to{" "}
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][8].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 8, e.target.value, true)
                                                    }
                                                />
                                                !
                                            </div>
                                            <div className="paragraf">
                                                <strong>Axebug:</strong> But, what are we going to do?
                                            </div>
                                            <div className="paragraf">
                                                <strong>Robug 13:</strong> You are the only ones who can
                                                find the{" "}
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][9].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 9, e.target.value, true)
                                                    }
                                                />
                                                of energy we need.
                                            </div>
                                            <div className="paragraf">
                                                <strong>Axebug:</strong> Where are we going to find it?
                                            </div>
                                            <div className="paragraf">
                                                <strong>Dung beetle:</strong> Can we roll and find it?
                                            </div>
                                            <div className="paragraf">
                                                <strong>Ladybug:</strong> That takes the biscuit! I want
                                                to go home!
                                            </div>
                                            <div className="paragraf">
                                                <strong>Axebug:</strong> Guys, pull yourself together.
                                                They need help. We can do this.
                                            </div>
                                            <div className="paragraf">
                                                <strong>Robug 13:</strong> You need to find the element
                                                of designexium in deep space. That’s our{" "}
                                                <input
                                                    className="form-input textformat"
                                                    type="text"
                                                    value={answer.result[0][1][10].student}
                                                    onChange={(e) =>
                                                        setStudentOpenAnswer(0, 1, 10, e.target.value, true)
                                                    }
                                                />
                                                .
                                            </div>
                                            <div className="paragraf">
                                                <strong>Axebug:</strong> Allright then. Let the
                                                adventure begin!
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                    {pageNo === 2 ? (
                                        <React.Fragment>
                                            <div className="alert alert-dark" role="alert">
                                                <strong>
                                                    {" "}
                                                    C. Match the words with their definitions
                                                </strong>
                                            </div>

                                            <div className="row">
                                                <div className="col-2"> 1. Allow</div>
                                                <div className="col-1">
                                                    <input
                                                        className="form-input w-100"
                                                        type="text"
                                                        value={answer.result[0][2][1].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(0, 2, 1, e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="col-9">
                                                    <div className="paragraf alert alert-success">
                                                        <strong>A. </strong> A frame showing motions.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2"> 2. Screen</div>
                                                <div className="col-1">
                                                    <input
                                                        className="form-input w-100"
                                                        type="text"
                                                        value={answer.result[0][2][2].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(0, 2, 2, e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="col-9">
                                                    <div className="paragraf alert alert-success">
                                                        <strong>B. </strong> To give permission.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2"> 3. Immediately</div>
                                                <div className="col-1">
                                                    <input
                                                        className="form-input w-100"
                                                        type="text"
                                                        value={answer.result[0][2][3].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(0, 2, 3, e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="col-9">
                                                    <div className="paragraf alert alert-success">
                                                        <strong>C. </strong> Any type of call for help.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2"> 4. Return</div>
                                                <div className="col-1">
                                                    <input
                                                        className="form-input w-100"
                                                        type="text"
                                                        value={answer.result[0][2][4].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(0, 2, 4, e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="col-9">
                                                    <div className="paragraf alert alert-success">
                                                        <strong>D. </strong> Very urgently.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2"> 5. SOS Call</div>
                                                <div className="col-1">
                                                    <input
                                                        className="form-input w-100"
                                                        type="text"
                                                        value={answer.result[0][2][5].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(0, 2, 5, e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="col-9">
                                                    <div className="paragraf alert alert-success">
                                                        <strong>E. </strong> Go back to a position or way.
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                    {pageNo === 3 ? (
                                        <React.Fragment>
                                            <div className="alert alert-dark" role="alert">
                                                <strong> D. Unscramble the words.</strong>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 1. oorp</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][1].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                1,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 2. locoyn</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][2].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                2,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 3. vltare</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][3].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                3,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 4. qedharuratre</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][4].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                4,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 5. hkint</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][5].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                5,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 6. gxabeu</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][6].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                6,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 7. nerutr</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][7].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                7,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 8. seturarue</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][8].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                8,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 9. grtate</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][9].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                9,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3"> 10. cregahrege</div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-input textformat"
                                                        type="text"
                                                        value={answer.result[0][3][10].student}
                                                        onChange={(e) =>
                                                            setStudentOpenAnswer(
                                                                0,
                                                                3,
                                                                10,
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                    {pageNo === 4 ? (
                                        <React.Fragment>
                                            <div className="alert alert-dark" role="alert">
                                                <strong>
                                                    {" "}
                                                    A. Listen to the audio. Then, repeat the sentences
                                                    clearly.
                                                </strong>
                                            </div>
                                            <div className="alert alert-dark" role="alert">
                                                <audio
                                                    controls
                                                    className="w-100"
                                                    style={{backgroundColor: "black", height: 45}}
                                                >
                                                    <source
                                                        src={`https://app.axebug.com/axebug/assets/p0135_002_c2_e8.mp3`}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                            </div>
                                            <div className="border border-success p-3 mt-3">
                                                <SpechText
                                                    getSpeechText={getSpeechText}
                                                    questionNumber="S1"
                                                />
                                            </div>
                                            <div className="alert alert-dark" role="alert">
                                                <audio
                                                    controls
                                                    className="w-100"
                                                    style={{backgroundColor: "black", height: 45}}
                                                >
                                                    <source
                                                        src={`https://app.axebug.com/axebug/assets/p0146_001_c2_e8.mp3`}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                            </div>
                                            <div className="border border-success p-3 mt-3">
                                                <SpechText
                                                    getSpeechText={getSpeechText}
                                                    questionNumber="S2"
                                                />
                                            </div>
                                            <div className="alert alert-dark" role="alert">
                                                <audio
                                                    controls
                                                    className="w-100"
                                                    style={{backgroundColor: "black", height: 45}}
                                                >
                                                    <source
                                                        src={`https://app.axebug.com/axebug/assets/p0150_001_c2_e8.mp3`}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                            </div>
                                            <div className="border border-success p-3 mt-3">
                                                <SpechText
                                                    getSpeechText={getSpeechText}
                                                    questionNumber="S3"
                                                />
                                            </div>
                                            <div className="alert alert-dark" role="alert">
                                                <audio
                                                    controls
                                                    className="w-100"
                                                    style={{backgroundColor: "black", height: 45}}
                                                >
                                                    <source
                                                        src={`https://app.axebug.com/axebug/assets/p0157_001_c2_e8.mp3`}
                                                        type="audio/mpeg"
                                                    />
                                                </audio>
                                            </div>
                                            <div className="border border-success p-3 mt-3">
                                                <SpechText
                                                    getSpeechText={getSpeechText}
                                                    questionNumber="S4"
                                                />
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                    {pageNo === 5 ? <FinishExam/> : null}
                                </div>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        {pageNo !== 0 ? (
                            <div className="col-auto">
                                <button className="btn btn-success" onClick={() => prevPage()}>
                                    PREV
                                </button>
                            </div>
                        ) : null}
                        {pageNo !== maxPage - 1 ? (
                            <div className="col-auto pl-2">
                                <button className="btn btn-success" onClick={() => nextPage()}>
                                    NEXT
                                </button>
                            </div>
                        ) : null}
                        {pageNo === maxPage - 1 ? (
                            <div className="col-auto pl-2">
                                <button
                                    className="btn btn-success"
                                    onClick={() => props.sendExam(answer, "QUIZ", true)}
                                >
                                    FINISH EXAM
                                </button>
                            </div>
                        ) : null}
                        {/* <div className="col pl-4">
              <button className='btn btn-info pl-4' onClick={() => props.sendExam(answer,"QUIZ", false)}>SAVE EXAM</button>
            </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
