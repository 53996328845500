import React from 'react'
import Menu from './Menu'

export default function EpisodeList() {
    return (
        <div className="container">
        <Menu />
        episode listesi...
    </div>
    )
}
